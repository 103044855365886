<template>
  <nav 
      class="navbar navbar-dark fs--1 font-weight-semi-bold navbar-standard navbar-theme navbar-expand-lg fixed-top py-0"
      :style="navBackground">
      <div class="container">
        <router-link class="navbar-brand" to="/" exact>
          <div class="d-flex align-items-center text-primary ">
            <img 
            class="mr-1" 
            src="@/assets/img/illustrations/bplan-logo.png" style="filter: contrast(120%);" alt="" width="40" />
            <span class="text-white font-proxima">
              ТВОЙ
              <span class="text-800"
                style="font-size: 1rem">
                бизнес-план
              </span> 
            </span>
          </div>
        </router-link>
        <button 
          class="navbar-toggler collapsed" 
          type="button" 
          data-toggle="collapse" 
          data-target="#navbarStandard" 
          aria-controls="navbarStandard" 
          aria-expanded="false" 
          aria-label="Toggle navigation"
          @click="navToggle()">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarStandard">
          <ul class="d-flex navbar-nav align-items-center ml-auto">
            <li v-if="false"
              class="d-flex nav-item nav-item-top fs--0 text-uppercase font-proxima font-weight-light align-items-center px-1" style="height: 55px;">
              <a class="nav-link text-1000" href="#">Как это работает</a>
            </li>
            <li class="d-flex nav-item nav-item-top fs--0 text-uppercase font-proxima font-weight-light align-items-center px-1" style="height: 55px;">
              <router-link class="nav-link text-1000 text-center" to="/features"
                style="min-width: 100px;">
                Функции
              </router-link>
            </li>
            <router-link 
              class="d-flex nav-item nav-item-top fs--0 text-uppercase font-proxima font-weight-light align-items-center px-1"
              style="height: 55px;" 
              to="/pricing" 
              tag="li">
              <div class="nav-link text-1000 text-center"
                style="min-width: 100px;">
                Стоимость
              </div>
            </router-link>
            <li  class="d-flex nav-item nav-item-top fs--0 text-uppercase font-proxima font-weight-light align-items-center px-1" 
              style="height: 55px;">
              <a class="nav-link text-1000 text-center" href="#"
                style="min-width: 130px;">
                Пользователи
              </a>
            </li>
            <li class="d-flex nav-item nav-item-top fs--0 text-uppercase font-proxima font-weight-light align-items-center px-1" style="height: 55px;">
              <a class="nav-link text-1000 text-center" href="#"
                style="min-width: 120px;">
                Решения
              </a>
            </li>
            <li class="d-flex nav-item nav-item-top fs--0 text-uppercase font-proxima font-weight-light align-items-center px-1" style="height: 55px;">
              <a href="https://articles.your-plan.ru"
                class="nav-link text-1000 text-center"
                style="min-width: 120px;">
                Публикации
              </a>
            </li>
          </ul>

        <!-- Менюшка для работы с аккаунтом -->
        <ul class="navbar-nav align-items-center ml-auto mt-2">
          <li class="nav-item mb-2 mr-2">
            <router-link v-if="currentUser"
              class="btn btn-outline-success btn-sm font-proxima font-weight-light" to="/bplan">
              <i class="fas fa-sign-in-alt mr-2"></i>
              Рабочий кабинет
            </router-link>
          </li>
          <li class="nav-item mb-2 mr-2">
            <router-link v-if="!currentUser"
              class="btn btn-outline-light btn-sm font-proxima font-weight-light" to="/login">
              <i class="fas fa-sign-in-alt mr-2"></i>
              Войти
            </router-link>
          </li>
          <li class="nav-item mb-2">
            <router-link v-if="!currentUser"
              class="btn btn-warning btn-sm font-proxima font-weight-light" to="/registration">
              <i class="fas fa-key mr-1"></i>
              Регистрация
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import firebase from 'firebase'

export default {
  data: () => ({
    scrollPosition: null,
    navState: false
  }),
  components: {
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll);
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY
    },
    navToggle() {
      this.navState = !this.navState
    }
  },
  computed: {
    currentUser() {
      return firebase.auth().currentUser
    },
    
    navBackground() {
      var transparent = 0
      if (this.scrollPosition < 190) {
        transparent = this.scrollPosition / 200
      } else {
        transparent = 0.9
      }

      if (this.navState) {
        transparent = 0.9
      }

      return `background-color: rgb(11, 23, 39, ${transparent});`
    }
  },
  destroy() {
  window.removeEventListener('scroll', this.updateScroll)
  }
}

</script>

<style lang="scss" scoped>

li {
  &.router-link-active {
    background-color: #ccd1d880;
    border-bottom: 2px solid rgba(255, 255, 255, 0.5);
    cursor: default !important;
    .nav-link {
      color: white !important;
      font-weight: bold;
    }
  }
}


.nav-item-top {
  &:hover {
    background-color: #ccd1d880;
    color: white;
    border-bottom: 2px solid rgba(255, 255, 255, 0.5);
    cursor: pointer;
    .nav-link {
      color: white !important;
      font-weight: bold;
    }
  }

}
</style>