<template>
  <main class="main" 
    style="font-family: 'Proxima Nova' !important; margin-top: 55px; margin-bottom: 0" id="top">
    <app-navbar />

    <!-- ============================================-->
    <!-- <section> begin ============================-->
    <section class="py-0 overflow-hidden" id="banner">

      <div 
        class="bg-holder overlay" 
        style="background-image:url(/img/generic/bg-7.jpg);background-position: center bottom;">
      </div>
      <!--/.bg-holder-->

      <div class="container">
        <div class="row justify-content-center text-center pt-5 pt-lg-7 pb-lg-9 pb-xl-0">
          <div class="col-md-12">
            <h1 class="lead fs-2 fs-sm-4 fs-md-5 mb-3 pb-3 font-proxima font-weight-bold">
              Создай бизнес-план и<br> 
              преврати свою идею в реальность
            </h1>
          </div>
          <div class="col-md-12">
            <div class="card mb-3">
              <div class="card-body">
                <div class="row no-gutters">
                  <p class="font-proxima align-bottom text-1000" style="width: 100%">
                    Выбери наиболее подходящий план подписки и заверши регистрацию, это займет менее минуты. <br>
                    Отменить или изменить подписку можно в любое время!
                  </p>
                  <div class="col-lg-4 border-top border-bottom">
                    <div class="h-100">
                      <div class="text-center p-4">      
                        <h2 class="font-weight-medium my-2"> 
                          <sup class="font-weight-normal fs-2 mr-1">₽</sup>
                          <span>475</span>
                          <small class="fs--1 text-700"> / месяц </small> 
                        </h2>
                        <p class="mt-3">При оплате за 12 месяцев <br>
                          <span class="badge badge-soft-success badge-pill ml-2">
                            экономия 50%
                          </span>
                        </p> 
                        <hr>
                        <ul class="list-unstyled fs--1 mb-4">
                          <li class="mb-1">
                            Функционал сервиса без ограничений
                          </li>
                          <li class="mb-1">
                            Многопользовательский режим
                          </li>
                          <li class="mb-1">
                            Приоритетная поддержка
                          </li>
                        </ul>
                        <router-link to="/registration?plan-type=year"
                          class="btn btn-outline-primary font-proxima font-weight-thin">
                          ВЫБРАТЬ И НАЧАТЬ!
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 border-top border-bottom">
                    <div class="h-100" style="background-color: rgba(115, 255, 236, 0.18)">
                      <div class="text-center p-4">      
                        <h2 class="font-weight-medium my-2"> 
                          <sup class="font-weight-normal fs-2 mr-1">₽</sup>
                          <span>710</span>
                          <small class="fs--1 text-700"> / месяц </small> 
                        </h2>
                        <p class="mt-3">При оплате за 3 месяца <br>
                          <span class="badge badge-soft-success badge-pill ml-2">
                            экономия 25%
                          </span>
                        </p> 
                        <hr>
                        <ul class="list-unstyled fs--1 mb-4">
                          <li class="mb-1">
                            Функционал сервиса без ограничений
                          </li>
                          <li class="mb-1">
                            Многопользовательский режим
                          </li>
                          <li class="mb-1">
                            Приоритетная поддержка
                          </li>
                        </ul>
                        <router-link to="/registration?plan-type=quarter"
                          class="btn btn-primary font-proxima font-weight-thin">
                          ВЫБРАТЬ И НАЧАТЬ!
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 border-top border-bottom">
                    <div class="h-100">
                      <div class="text-center p-4">      
                        <h2 class="font-weight-medium my-2"> 
                          <sup class="font-weight-normal fs-2 mr-1">₽</sup>
                          <span>950</span>
                          <small class="fs--1 text-700"> / месяц </small> 
                        </h2>
                        <p class="mt-3">При ежемесячной оплате <br>
                          <span class="badge badge-soft-info badge-pill ml-2">
                            оплачивай при необходимости
                          </span>
                        </p> 
                        <hr>
                        <ul class="list-unstyled fs--1 mb-4">
                          <li class="mb-1">
                            Функционал сервиса без ограничений
                          </li>
                          <li class="mb-1">
                            Многопользовательский режим
                          </li>
                          <li class="mb-1">
                            Стандартная поддержка
                          </li>
                        </ul>
                        <router-link to="/registration?plan-type=month"
                          class="btn btn-outline-primary font-proxima font-weight-thin">
                          ВЫБРАТЬ И НАЧАТЬ!
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex col-12 text-center mt-2">
                    <div class="d-flex align-items-center money-back-wrapper">
                      <img src="../assets/img/icons/30-money-back.png" class="mr-2" style="height: 80px;">                      
                      <p class="fs--1 m-0">
                        Попробуйте сервис "<strong>ТВОЙ</strong><small class="text-800 text-uppercase"> бизнес-план</small>" 
                        <strong class="text-1000"> без риска</strong> потери денег в течение 30 дней. <br> 
                        Если не понравится, мы вернем 100% платежа.
                      </p>
                    </div> 
                  </div>
                  <div class="col-12 text-center">
                    <ul style="list-style: none; display: inline-flex; margin: 0 auto 0 auto;">
                      <li class="mr-3">
                        <i class="fas fa-check"></i>
                        Простое и понятное ценообразование
                      </li>
                      <li class="mr-3">
                        <i class="fab fa-creative-commons-nc"></i>
                        Нет дополнительных платежей
                      </li>
                      <li class="mr-3">
                        <i class="fas fa-key mr-1"></i>
                        Просто регистрируйся и начинай
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
      <!-- end of .container-->

    </section>
    <!-- <section> close ============================-->
    <!-- ============================================-->

     <!-- ============================================-->
    <!-- <section> begin ============================-->
    <section class="bg-light text-center pt-5">
      <div class="container">
        <h3 class="font-proxima title">Что вы получаете в результате подписки?</h3>
        <div class="row">
          <div class="col-12 col-lg-6 order-lg-1 mb-3">
            <h5 class="font-proxima text-left mb-3 pt-5">
              Бизнес-план адаптирован для получения инвестиций </h5>
            <p class="font-proxima text-left">
              Один из наших клиентов, резюмировал работу с сервисм следующим образом: "<strong>ТВОЙ</strong><small class="text-800 text-uppercase"> бизнес-план</small> - это как нанять группу профессионалов, состоящую из: эксперта-консультанта по бизнес-планировани, профессионального финансиста и высококачественного дизайнера. Только значительно лучше, проще и дешевле!" 
              <br> <br>
              <strong>Гарантировано</strong>, финансовые таблицы и графики твоего бизнес-плана будут аккуратными, а окончательный вариант будет выглядеть на все 100% и готовым произвести впечатление на инвесторов, деловых партнеров, банки и кредиторов.
            </p>
          </div>
          <div class="col-12 col-lg-6 order-lg-1 mb-3">
            <img src="../assets/img/illustrations/feature_plan.png" class="px-3" style="max-width: 100%; height: auto;" alt="">
          </div>
          <div class="col-12 col-lg-6 order-lg-1 mb-3">
            <img src="../assets/img/illustrations/financial-forecast.png" class="px-3" style="max-width: 100%; height: auto;" alt="">
          </div>
          <div class="col-12 col-lg-6 order-lg-1 mb-3">
            <h5 class="font-proxima text-left mb-3 pt-5">Современный, интуитивно понятный интерфейс</h5>
            <p class="font-proxima text-left">
               Мы убрали все лишние элементы не имеющие отношения к превращению идеи в реальный бизнес-план, это дало легкую и понятную структуру программы, которая позволяет интуитивно перемещаться от секции к секции.
            </p>
          </div>
          <div class="col-12 col-lg-6 order-lg-1 mb-3">
            <h5 class="font-proxima text-left mb-3 pt-5">Возможность отслеживать ежедневный прогресс</h5>
            <p class="font-proxima text-left">
               Панель мониторинга эффективности <strong>ТВОЙ</strong><small class="text-800 text-uppercase"> бизнес-план</small> - это бизнес-панель богатая визуальными и информационными данными, позволяющая держать палец на пульсе эффективности твоего бизнеса, выделяя наиболее важную информацию требующую контроля. В любое время ты быдешь точно знать, как насколько хорошо работает бизнес, а также создавать полноценные отчеты, за считанные минуты.
            </p>
          </div>
          <div class="col-12 col-lg-6 order-lg-1 mb-3">
            <img src="../assets/img/illustrations/feature_scoreboard.png" class="px-3" style="max-width: 100%; height: auto;" alt="">
          </div>
          <div v-if="false"
            class="col-12 col-lg-6 order-lg-1 mb-3">
            <img src="../assets/img/illustrations/feauture_SamplePlans.png" class="px-3" style="max-width: 100%; height: auto;" alt="">
          </div>
          <div v-if="false"
            class="col-12 col-lg-6 order-lg-1 mb-3">
            <h5 class="font-proxima text-left mb-3 pt-5">Более 100 готовых бизнес-планов</h5>
            <p class="font-proxima text-left">
               Зарядись вдохновение и разбуди в себе писателя с помощью нашей библиотеки готовых бизнес-планов, состоящей из более чем 100 готовых бизнес-планов, охватывающих все основные отрасли промышленности. С помощью <strong>ТВОЙ</strong><small class="text-800 text-uppercase"> бизнес-план</small> ты сэкономишь сотни часов, используя, в качестве примера, готовые планы как отправную точку для собственного плана, настраивая и адаптируя его по мере продвижения.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-6 mb-4">
            <h3 class="font-proxima font-weight-thin">
              А также еще много чего полезного!
            </h3>
          </div>
          <div class="col-lg-4 text-left">
            <h5 class="font-weight-light font-proxima border-top mx-1 pt-3">Расчет финансовых показателей</h5>
            <p class="features">
              Больши никакой возни с формулами! Финансовые таблицы, диаграммы и отчеты рассчитываются автоматически.
            </p>
          </div>
          <div class="col-lg-4 text-left">
            <h5 class="font-weight-light font-proxima border-top mx-1 pt-3">Сравнительные показатели</h5>
            <p class="features">
              Узнайте, как соотносится ваша компания с другими по отрасли, размеру или расположению.
            </p>
          </div>
          <div v-if="false"
            class="col-lg-4 text-left">
            <h5 class="font-weight-light font-proxima border-top mx-1 pt-3">Неограниченное количество бизнес-планов</h5>
            <p class="features">
              Больше не нужно ограничивать свой творческий потенциал. Создавай столько бизнес-планов, сколько нужно, никаких ограничений.
            </p>
          </div>
          <div class="col-lg-4 text-left">
            <h5 class="font-weight-light font-proxima border-top mx-1 pt-3">Пошаговые инструкции</h5>
            <p class="features">
              Наши инструкции позволят лего заполнить пробелы и помогают пройти весь процесс от начала и до конца.
            </p>
          </div>
          <div v-if="false"
            class="col-lg-4 text-left">
            <h5 class="font-weight-light font-proxima border-top mx-1 pt-3">
              Более 100 готовых бизнес-планов
            </h5>
            <p class="features">
              В нашей библиотеке более 100 готовых бизнес-плана, которые можно использовать в качестве примера.
            </p>
          </div>
          <div class="col-lg-4 text-left">
            <h5 class="font-weight-light font-proxima border-top mx-1 pt-3">
              Доступ в любоее время и в любом месте
            </h5>
            <p class="features">
              Вносите изменения в свой бизнес-план или отслеживайте эффективность ведения бизнеса откуда угодно, где есть интернет.
            </p>
          </div>
          <div class="col-lg-4 text-left">
            <h5 class="font-weight-light font-proxima border-top mx-1 pt-3">
              Контрольные точки
            </h5>
            <p class="features">
              Создайте контрольные точки ключевых событий вашего бизнес-плана и отслеживайте прогресс их достижения.
            </p>
          </div>
          <div
            class="col-lg-4 text-left">
            <h5 class="font-weight-light font-proxima border-top mx-1 pt-3">
              Возможность экспорта в PDF
            </h5>
            <p class="features">
              Пивлекательный и проффесионально оформленный бизнес план легко сохраняется в PDF.
            </p>
          </div>
          <div class="col-lg-4 text-left">
            <h5 class="font-weight-light font-proxima border-top mx-1 pt-3">
              Кросс-платформенность
            </h5>
            <p class="features">
              <strong>ТВОЙ</strong><small class="text-800 text-uppercase"> бизнес-план</small> является облачным программным продуктом, а это значит, что его можно запускат на любом устройстве (MacPC, Windows и даже планшете).
            </p>
          </div>
          <div class="col-lg-4 text-left">
            <h5 class="font-weight-light font-proxima border-top mx-1 pt-3">Лекгий экспорт</h5>
            <p class="features">
              Свободно экспортируйте свой бизнес-план в форматы Word или PDF. Питч можно также экспортировать в PowerPoint для создания эффектного слайд-шоу.
            </p>
          </div>
          <div v-if="false"
            class="col-lg-4 text-left">
            <h5 class="font-weight-light font-proxima border-top mx-1 pt-3">Поддержка экспертов</h5>
            <p class="features">
              Видеоуроки и разъяснение ключевых терминов от известных экспертов по бизнес-планированию.
            </p>
          </div>
          <div class="col-lg-4 text-left">
            <h5 class="font-weight-light font-proxima border-top mx-1 pt-3">Мониторинг показателей</h5>
            <p class="features">
              Визуальная панель мониторинга эффективности показателей, в режиме реального времени, позволяет отслеживать исполнение бюджетов и достижения целей по продажам.
            </p>
          </div>
          <div class="col-lg-4 text-left">
            <h5 class="font-weight-light font-proxima border-top mx-1 pt-3">Эффективная презентация</h5>
            <p class="features">
              Создайте резюме наполненное инфографикой для проведения "быстрых" презентаций своей идеи.
            </p>
          </div>
          <div class="col-lg-4 text-left">
            <h5 class="font-weight-light font-proxima border-top mx-1 pt-3">Клиентская поддержка</h5>
            <p class="features font-weight-thin">
              Если возникли затруднения, можно связаться с нашей службой поддержки по телефону, электронной почте или онлайн-чату.
            </p>
          </div>
          <div class="col-lg-4 text-left">
            <h5 class="font-weight-light font-proxima border-top mx-1 pt-3">
              Безопасность и уверенность
            </h5>
            <p class="features font-weight-thin">
              Ваши данные полностью зашифрованны и защищены. Гарантировано!
            </p>
          </div>
        </div>
      </div>
    </section>
    <!-- <section> close ============================-->
    <!-- ============================================-->


     <!-- ============================================-->
    <!-- <section> begin ============================-->
    <section class="bg-600 text-center pt-5 pb-7">
      <div class="container">
        <div class="card">
            <div class="card-header">
              <h3 class="mb-0 font-weight-thin">
                Часто задаваемые вопросы
              </h3>
            </div>
            <div class="card-body bg-light">
              <div class="row">
                <div class="col-lg-6 text-left pr-3">
                  <h5 class="fs-0 mb-2">
                    В чем преимущества годовой подписки?
                  </h5>
                  <p class="fs--1">
                     Использование сервиса <strong>ТВОЙ</strong><small class="text-800 text-uppercase"> бизнес-план</small>, в течение целого года, позволяет отслеживать прогресс и видеть, как бизнес работает в реальном мире и насколько хорошо сочетается с планом. Подобная аналитика, значительно облегчает поиск новых возможностей для роста, сокращения расходов и развития бизнеса. Кроме того, годовой пакет дает хорошую экономию средств, почти на 50% по сравнению с ежемесячной оплатой. <br>
                     На целый год, вы получаете надежный путиводитель для достижения поставленных бизнес целей.
                  </p>
                  <h5 class="fs-0 mb-2">
                    Есть ли бесплатная пробная версия?
                  </h5>
                  <p class="fs--1">
                    Сервис <strong>ТВОЙ</strong><small class="text-800 text-uppercase"> бизнес-план</small> обеспечивает 30-дневную гарантию возврата денег, которая действует так же, как и бесплатная пробная версия (тем неменее, для начала работы нам требуется кредитная карта). Поработайте с программой в течении этого времени и если останетесь недовольны, просто свяжитесь с нами для полного возврата денег. Без каких-либо лишних вопросов.
                  </p>
                  <h5 class="fs-0 mb-2">
                    Насколько защищены мои данные?
                  </h5>
                  <p class="fs--1">
                    Вашы бизнес-планы останются безопасным и полностью конфиденциальным.
                    Защита данных, которые Вы доверяете сервису <strong>ТВОЙ</strong><small class="text-800 text-uppercase"> бизнес-план</small>, является нашим главным приоритетом. Мы  использует физические, процедурные и технические средства защиты для сохранения целостности и безопасности вашей информации. Мы регулярно делаем резервные копии ваших данных, чтобы предотвратить их потерю и помочь в восстановлении. Кроме того, мы размещаем данные в защищенных сертифицированных центрах обработки данных (SSAE 16 / SOC1), используем брандмауэры и ограничения доступа на наших серверах для лучшей защиты вашей информации. <br> 
                    Для получения дополнительной информации, пожалуйста, ознакомьтесь с нашей политикой безопасности и конфиденциальности.
                  </p>
                  <h5 class="fs-0 mb-2">
                    Как работает 30-дневная гарантия возврата денег?
                  </h5>
                  <p class="fs--1">
                    Если вы обнаружите, что сервису <strong>ТВОЙ</strong><small class="text-800 text-uppercase"> бизнес-план</small> не отвечает вашим требованиям по какой-либо причине, просто свяжитесь с нами по электронной почте, телефону или в приложении в течение первых 30 дней, и мы вернем вам деньги.
                  </p>
                  <h5 class="fs-0 mb-2">
                    Какие способы оплаты принимаются?
                  </h5>
                  <p class="fs--1 mb-lg-0">
                    Мы принимаем кредитные и дебетовые карты Visa и MasterCard. Как уже было сказано выше, платежи защищены нашей 30-дневной гарантией возврата денег вне зависимости от способа оплаты.
                  </p>
                </div>
                <div class="col-lg-6 text-left pl-4">
                  <h5 class="fs-0 mb-2">
                    Применим ли сервис "<strong>ТВОЙ</strong><small class="text-800 text-uppercase"> бизнес-план</small>" для моей отрасли?
                  </h5>
                  <p class="fs--1">
                    <strong>ТВОЙ</strong><small class="text-800 text-uppercase"> бизнес-план</small> применим к любой отрасли: розничной торговли, ресторанов, сферы услуг, здравоохранения и прочих секторов среднего и малого бизнеса. У нас вы также найдете более 100 готовых бизнес-планов, которые можно использовать в качестве руководства при написании своего собственного плана. <br>
                  </p>
                  <h5 class="fs-0 mb-2">
                    Смогу ли я изменить или отменить подписку после регистрации?
                  </h5>
                  <p class="fs--1">
                    Да, <strong>ТВОЙ</strong><small class="text-800 text-uppercase"> бизнес-план</small> не налагает каких-либо долгосрочных обязательств. Вы также можете перейти с ежемесячной подписки на годовую в любое время - без каких-либо штрафов.
                  </p>
                  <h5 class="fs-0 mb-2">
                    Что произойдет с моим бизнес-планом, если я удалю свою учетную запись?
                  </h5>
                  <p class="fs--1">
                    Ваша бизнес-план принадлежит вам на 100% в любой момент времени. Вы можете забрать его с собой, если надумаете уйти. Приняв решение прекратить подписку на <strong>ТВОЙ</strong><small class="text-800 text-uppercase"> бизнес-план</small>, просто экспортируйте свой бизнес-план в Word или PDF, перед финальным выходом. Вы также можете приостановить свою учетную запись за небольшую ежемесячную плату, и мы сохраним все ваши данные в безопасности, до момента когда вы будете готовы вернуться к нам.
                  </p>
                  <h5 class="fs-0 mb-2">
                    Смогу ли я работать над несколькими бизнес-планами?
                  </h5>
                  <p class="fs--1">
                    Да, вы можете создать неограниченное количество бизнес-планов на одном аккаунте. Однако одновременно можно открыть и едактировать только один бизнес-план. Если вам нужно изменять несколько бизнес-планов одновременно, вы можете обновить свою учетную запись до профессионального участника.
                  </p>
                  <h5 class="fs-0 mb-2">
                    Что делать, если у меня возникнут еще вопросы?
                  </h5>
                  <p class="fs--1 mb-0">
                    Для начала, пожалуйста, ознакомьтесь с нашей основной страницей <router-link to="/faq">Часто задаваемых вопросов</router-link> или <router-link to="/contacts">свяжитесь с нами</router-link>, и мы будем рады помочь вам лично. Кроме того, в нашем <router-link to="#">центре поддержки клиентов</router-link> мы поможем разобраться со всеми вопросам использования программы <strong>ТВОЙ</strong><small class="text-800 text-uppercase"> бизнес-план</small>.
                  </p>
                </div>
              </div>
            </div>
            <div class="card-footer py-3">
              <div class="text-center">
                <h6 class="fs-0 font-weight-normal">Все еще остались вопросы?</h6><a class="btn btn-falcon-primary btn-sm" href="#" data-toggle="modal" data-target="#exampleModal">Задавайте!</a>
              </div>
              <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header bg-light">
                      <h5 class="modal-title" id="exampleModalLabel">
                        Задайте Ваш вопрос
                      </h5>
                      <button 
                        class="close" 
                        type="button" 
                        data-dismiss="modal" 
                        aria-label="Close">
                        <span class="font-weight-light" aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <form>
                        <div class="form-group">
                          <label for="name">
                            Ваше имя:
                          </label>
                          <input class="form-control" id="name" type="text">
                        </div>
                        <div class="form-group">
                          <label for="emailModal">
                            Ваш e-mail:
                          </label>
                          <input class="form-control" id="emailModal" type="email">
                        </div>
                        <div class="form-group">
                          <label for="question">Вопрос:</label>
                          <textarea class="form-control" id="question" rows="4">
                          </textarea>
                        </div>
                      </form>
                    </div>
                    <div class="modal-footer bg-light rounded">
                      <button 
                        class="btn btn-secondary btn-sm" 
                        type="button" 
                        data-dismiss="modal">
                        Закрыть
                      </button>
                      <button class="btn btn-primary btn-sm" type="submit">
                        Отправить
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
      <!-- end of .container-->

    </section>
    <!-- <section> close ============================-->
    <!-- ============================================-->

    <app-footer />

  </main>
</template>

<script>
import Navbar from './Navbar'
import FooterMenu from './FooterMenu';

export default {
  components: {
    appNavbar: Navbar,
    appFooter: FooterMenu
  }

}
</script>

<style lang="scss" scoped>

.title {
  border-bottom: 1px solid lightgray;
  font-weight: 100;
  padding-bottom: 5px;
  margin: 0 15rem 3rem 15rem; 
}

.chekmark {
  height: 50px; 
  width: 50px;
}

.features {
  max-width: 350px;
  width: 100%;
  font-family: 'Proxima Nova';
  font-weight: 100;
  font-size: 16px;
  line-height: 20px;
  margin: 0 auto 56px 0.4rem;
}

.feature-img {
  margin: 0 auto 26px;
  height: 300px;
  width: 300px;
  &.chekmark {
  height: 50px; 
  width: 50px;
}
}

.money-back-wrapper {
  max-width: 500px;
  margin: 0 auto 10px auto;
}

.money-back-badge {
    background-image:url(/img/illustrations/corner-4.png);
    // background-color: red;
    width: 60px;
    height: 60px;
    display: block;
    margin-right: 15px;
    position: relative;
    top: -5px;
}

.lead {
  background: rgba(200, 200, 200, 0.3);
  border-radius: 3px;
  padding: 3px 10px;
}

@media (max-width: 899px) {
  li {
    display: grid;
  }

}

@media (min-width: 990px) {
  li {
    margin-right: 5px;
  }
}

@media (max-width: 1199px) {
  .typed-center {
    align-items: center; 
    justify-content: center; 
    box-sizing: border-box;
  }
}

</style>